.rating_header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}
.acc_type_info {
  width: 100%;
  float: left;
  margin-top: 30px;
}
.st_left {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

[data-forhalf="★"] {
  margin-right: 5px;
}

.st_left span:last-child {
  margin-right: 0;
}

.st_left .rating_stars {
  font-family: Montserrat;
  font-size: 30px;
  font-weight: normal;
  margin-left: 30px;
  padding-top: 5px;
}

.st_left .rating_stars span {
  font-weight: 500;
}

.reviews_block {
  text-align: right;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
}

.wrongpass {
  margin-right: 15px;
}

.rating_info > div {
  border-bottom: 1px dashed #ccc;
  position: relative;
}

.rating_info div > span {
  background: #fff;
  top: 8px;
  padding: 0 3px;
}

.rating_info div > span:first-child {
  font-size: 16px;
  font-weight: 300;
  color: #9b9b9b;
  line-height: 40px;
}

.rating_info div > span:last-child {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  color: #000;
  line-height: 28px;
  position: absolute;
  top: 1px;
  right: 0;
  height: 40px;
}

.offers {
  max-width: 280px;
  width: 100%;
  background-color: #f1f1f1;
  padding: 14px 19px;
  margin: auto;
}

.offers .ul-tick li:before {
  line-height: 33px;
}

.offers .ul-tick li.there_is_0:before {
  font-family: "gorexgrad";
  font-size: 14px;
  line-height: 34px;
  position: absolute;
  left: 0;
  content: "\e80b";
  color: #999;
}

.offers .ul-tick li.there_is_0 {
  color: #999;
}

.offers li {
  font-size: 16px;
  font-weight: normal;
  line-height: 34px;
  color: #000;
}

.acc_type_info .rating_info > span {
  margin-right: 12px;
  display: inline-block;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  line-height: 2;
  border-bottom: 1px dashed #ccc;
  color: #9b9b9b;
  cursor: pointer;
}

.acc_type_info .rating_info > span.active {
  color: #68ac1d;
  font-weight: bold;
  border-bottom: 1px dashed #68ac1d;
}

.acc_type_info .rating_info {
  margin-bottom: 36px;
}

.acc_type_info .rating_info > span.show_options {
  display: inline-block;
  width: 100%;
  border: none;
  color: #000;
  margin-top: 10px;
}

.rating_info h2 {
  margin-bottom: 20px;
}
.show_options {
  margin-bottom: 20px;
  display: block;
  padding-top: 10px;
}

.show_options_cont span {
  display: inline-block;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  padding: 7px 15px;
  background-color: #f1f1f1;
  margin: 5px;
}
.rating_info input,
.rating_info select {
  padding: 0 5px;
  width: 240px;
  border-radius: 0;
}

.arrow_to_show {
  border: solid #4a90e2;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  margin: 0 0 3px 10px;
  transform: rotate(45deg);
  transition: 0.4s;
}

body[dir="rtl"] .arrow_to_show {
  margin: 0 10px 3px 0;
}

body[dir="rtl"] .arrow_to_show.u_turn {
  margin: 0px 10px 0px 0;
}

.extra_block {
  color: #4a90e2;
  cursor: pointer;
}

.u_turn {
  transform: rotate(-135deg);
  margin-bottom: 0;
}

.arrow_to_show:hover {
  cursor: pointer;
}

.broker_logo {
  max-width: 160px;
  height: 80px;
}

.main_with_left h2 > strong {
  color: #9b9b9b;
}

.text-overflow-60 {
  display: inline-block;
  width: auto;
  max-width: 56%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rating_wrap .rating_info div span.text-wrap {
  display: block;
  max-width: 60%;
  line-height: 25px;
  top: 15px;
  position: relative;
  float: right;
}
.bg_wrap .rating_wrap {
  display: flex;
  flex-wrap: wrap;
}

.broker_txt_block {
  margin-bottom: 40px;
}
.main_with_left.forex_company h1 {
  color: #7bab2e;
}
.main_with_left.forex_company,
.forex_company {
  border: solid 1px #dee2e6;
  padding: 20px 10px;
  vertical-align: center;
}

@media (max-width: 840px) {
  .rating_info {
    width: 100%;
    margin-bottom: 30px;
    margin-right: 0;
  }
}

@media (max-width: 550px) {
  .rating_header {
    flex-direction: column;
    align-items: center;
  }

  .st_left .rating_stars {
    font-size: 20px;
    padding-top: 8px;
  }

  .show_options {
    text-align: center;
    padding-top: 0;
    margin-top: -20px;
  }

  .show_options_cont {
    display: flex;
    flex-flow: row wrap;
  }
  .show_options_cont span {
    width: calc(50% - 10px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .show_options_cont span:last-child,
  .show_options_cont span:first-child {
    margin-left: 5px;
  }

  .acc_type_info .rating_info > span {
    margin: 0 12px 20px;
    width: calc(50% - 24px);
    text-align: center;
    font-size: 14px;
  }
}

@media (max-width: 400px) {
  .fb .reviews_cont > div {
    flex-direction: column;
    align-items: flex-start;
  }

  .fb .left_part_reviews .rating {
    margin: 0;
  }
}
ul {
  margin: 0;
  padding: 0;
  list-style: outside none;
}

.ul-tick {
  position: relative;
}

.ul-tick li {
  line-height: 26px;
  position: relative;
  padding-left: 30px;
  text-align: left;
}

body[dir="rtl"] .ul-tick li {
  padding-right: 30px;
  padding-left: 0;
  text-align: right;
}

.ul-tick li:before {
  font-family: "gorexgrad";
  font-size: 13px;
  line-height: 25px;
  position: absolute;
  left: 0;
  content: "\E807";
  color: #7bab2e;
}

body[dir="rtl"] .ul-tick li:before {
  right: 0;
  left: auto;
}
textarea {
  resize: none;
}

.chooseFile input[type="file"] {
  display: none;
}

.chooseFile .custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  background-image: url("../../../assets/images/add-image.png");
  width: 170px;
  height: 170px;
}
.saveButton {
  width: 100%;
  background-color: black;
  color: white;
  border-radius: 20px;
  margin: 20px 0 20px 0px;
}

.show_options_cont {
  display: inline-block;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  padding: 7px 15px;
  background-color: #f1f1f1;
  margin: 5px;
}

button.cancel {
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
  margin-right: 5px;
  color: red;
}

button.cancel:before {
  font-family: "fxadmin";
  content: "\e80b";
  font-size: 10px;
}

.rating_info .item {
  display: inline-flex;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  line-height: normal;
  padding: 7px 15px;
  background-color: #f1f1f1;
  margin: 5px;
  border-bottom: none;
  align-items: center;
}
/*input[type="text"][title]:hover:after {*/
/*    font-style: italic;*/
/*    color:gray;*/
/*}*/

.hide {
  display: none;
}
.activeBtn {
  background-color: #5293ff;
  color: #fff;
  font-weight: bold;
  margin: 20px 5px;
}
.pasivBtn {
  background-color: gray;
  color: white;
  font-weight: bold;
  margin: 20px 5px;
}

.my-app {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto;
  min-height: 80px;
  box-sizing: border-box;
  padding: 30px;
}

.platformDevicesButton {
  width: 200px;
  height: 50px;
  background-color: cornflowerblue;
  color: black;
  margin: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.platformDevicesButton:hover {
  background-color: #92caed;
  border: 2px solid cornflowerblue;
}
.platformDevicesButtonActive {
  width: 200px;
  height: 50px;
  background-color: #4b6ded;
  color: black;
  font-weight: bold;
  margin: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.k-i-loading.k-example-loading {
  font-size: 64px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(63, 81, 181);
}

.k-i-loading.k-example-loading::before,
.k-i-loading.k-example-loading::after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  content: "";
  box-sizing: inherit;
  border-radius: 50%;
  border-width: 0.05em;
  border-style: solid;
  border-color: currentColor;
  border-top-color: transparent;
  border-bottom-color: transparent;
  background-color: transparent;
}
.k-icon.k-i-loading.k-example-loading::before,
.k-icon.k-i-loading::after {
  content: "";
}

.k-i-loading.k-example-loading::before {
  margin-top: -0.5em;
  margin-left: -0.5em;
  width: 1em;
  height: 1em;
  animation: k-loading-animation 0.7s linear infinite;
}

.k-i-loading.k-example-loading::after {
  margin-top: -0.25em;
  margin-left: -0.25em;
  width: 0.5em;
  height: 0.5em;
  animation: k-loading-animation reverse 1.4s linear infinite;
}

.example-wrapper {
  min-height: 280px;
  align-content: flex-start;
}
.example-wrapper p,
.example-col p {
  margin: 20px 0 10px;
}
.example-wrapper p:first-child,
.example-col p:first-child {
  margin-top: 0;
}
.example-col {
  display: inline-block;
  vertical-align: top;
  padding-right: 20px;
  padding-bottom: 20px;
}
.example-config {
  margin: 0 0 20px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.03);
  border: 1px solid rgba(0, 0, 0, 0.08);
}
.event-log {
  margin: 0;
  padding: 0;
  max-height: 100px;
  overflow-y: auto;
  list-style-type: none;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background-color: white;
}
.event-log li {
  margin: 0;
  padding: 0.3em;
  line-height: 1.2em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}
.event-log li:last-child {
  margin-bottom: -1px;
}
fieldset > div > span {
  background-color: #e6e6e6;
  margin: 5px;
  font-size: 17px;
  font-weight: bold;
  padding: 0 10px;
}

.wrongpass {
  font-weight: bold;
  color: red;
}
