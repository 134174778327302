.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0 0;
  border-radius: 4px;
}

.pagination > li {
  display: inline;
}

.pagination > li.disabled {
  display: none;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  z-index: 3;
  color: #fff;
  background: #5293ff;
  border-color: #2020ac;
  cursor: default;
}

.pagination > .disabled > a,
.pagination > .disabled > a:focus,
.pagination > .disabled > a:hover,
.pagination > .disabled > span,
.pagination > .disabled > span:focus,
.pagination > .disabled > span:hover {
  color: #777;
  background-color: #fff;
  border-color: #ddd;
  cursor: not-allowed;
}

.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #765e7e;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-right: 5px;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
