h3 {
  margin-left: 15px;
}
.fileInput {
  border: 1px solid gray;
  padding: 15px;
  cursor: pointer;
  width: 100%;
  color: gray;
}

.imgPreview {
  text-align: center;
  border: 1px solid gray;
  padding: 15px;
}

img {
  width: 100%;
  height: 100%;
}

.previewText {
  width: 100%;
  color: gray;
}

.submitButton {
  padding: 12px;
  margin-left: 10px;
  background: white;
  border: 4px solid lightgray;
  border-radius: 15px;
  font-weight: 700;
  font-size: 10pt;
  cursor: pointer;
}
submitButton:hover {
  background: #efefef;
}

.centerText {
  text-align: center;
  width: 500px;
}

.broker_txt_block input,
.broker_txt_block textarea {
  width: 100%;
  padding: 10px;
}

input[type="checkbox"] + label {
  display: block;
  cursor: pointer;
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"] + label:before {
  content: "\2714";
  border: 1px solid gray;
  border-radius: 0.2em;
  display: inline-block;
  vertical-align: bottom;
  color: transparent;
  transition: 0.2s;
  margin-right: 10px;
}

input[type="checkbox"] + label:active:before {
  transform: scale(0);
}

input[type="checkbox"]:checked + label:before {
  background-color: #fff;
  border-color: #fff;
  color: #fff;
}

input[type="checkbox"]:disabled + label:before {
  transform: scale(1);
  border-color: #aaa;
}

input[type="checkbox"]:checked:disabled + label:before {
  transform: scale(1);
  background-color: #bfb;
  border-color: #bfb;
}
